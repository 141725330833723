import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Navigate, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Input } from '@nextui-org/react'
import { Button } from '@/ui'
import { isIkea } from '@/api'
import { signin } from '@/api/auth'
import { pathes } from '@/routes'
import { useSlr } from '@/store'
import { selectSessionStatus } from '@/store/session/selectors'
import ikeaLogo from './Ikea_logo.svg.png'

// const enabledPassword = get(window, 'env.EnablePassword') || import.meta.env.VITE_ENABLE_PASSWORD

export const SignInPage = () => {
  const [loading, setLoading] = useState(false)
  const { status } = useSlr(selectSessionStatus)
  // const [showPasswordField, setShowPasswordField] = useState<boolean>(
  //   enabledPassword === 'true' || enabledPassword === true ? true : false,
  // )
  const navigate = useNavigate()
  const { register, handleSubmit } = useForm<{ email: string; password?: string }>({
    defaultValues: {
      email: '',
      password: '',
    },
  })

  if (status === 'success') {
    return <Navigate replace to={pathes.HOME} />
  }

  const onSubmit = (data: { email: string }) => {
    if (data.email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)) {
      setLoading(true)
      signin(data)
        .then((res) => {
          if (res.data.token) {
            window.location.href = `/?token=${res.data.token}&type=login`
          } else {
            navigate(pathes.SUCCESS, { replace: true })
          }
        })
        .catch((message) => toast.error(message))
        .finally(() => setLoading(false))
    } else {
      toast.error('Please enter a valid email address')
    }
  }

  return (
    <div className="p-10 max-sm:p-5">
      <header className="flex justify-between">
        <div className="flex items-center">
          {isIkea ? (
            <img src={ikeaLogo} width={200} alt="Company logotype" />
          ) : (
            <>
              <img src={ikeaLogo} width={200} alt="Company logotype" />
              {/* <strong className="text-heading2 ml-3.5">Ikea</strong> */}
            </>
          )}
        </div>
        {/*<div className="max-sm:text-right max-sm:pl-4">*/}
        {/*  <p className="text-neutral700">New to {IS_CISCO ? 'Purposefy' : 'Hypha'}?</p>*/}
        {/*  <Link*/}
        {/*    aria-label={`link to create an account`}*/}
        {/*    className="font-bold text-primary"*/}
        {/*    to="/signup"*/}
        {/*  >*/}
        {/*    Create an account*/}
        {/*  </Link>*/}
        {/*</div>*/}
      </header>
      <main className="grid justify-items-center pt-20 max-sm:p-0 max-sm:pt-10">
        <h1 className="text-title mb-3.5 max-sm:text-center">Welcome to IKEA Innovation</h1>
        <p className="text-neutral700 max-sm:text-center">
          {status === 'expired'
            ? 'Your session has expired, please login to get a magic link'
            : 'Please sign up or login'}
        </p>
        <form
          noValidate
          className="pt-8 grid max-w-[340px] w-full gap-5"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input
            key={'email'}
            type="text"
            labelPlacement={'outside'}
            placeholder="user@company.com"
            {...register('email', { required: true })}
          />
          {/* {showPasswordField && (
            <Input
              key={'password'}
              type="password"
              labelPlacement={'outside'}
              placeholder="Password"
              {...register('password', { required: false })}
            />
          )} */}
          <Button loading={loading} type="submit">
            Send Login Email
          </Button>
          {/* <Button type="button" onClick={() => setShowPasswordField(!showPasswordField)}>
            Use Password
          </Button> */}
        </form>
        <p>
          <b>Trouble Accessing the Site? Here&apos;s What to Do:</b>
          <br />
          <b>If you see the message “There may be an error in the scheme”:</b>
          <br />
          - Clear your browser&apos;s cache and cookies.
          <br />
          This issue occurs when temporary data stored in your browser expires or becomes corrupted,
          preventing the site from loading correctly.
          <br />
          <b>If the site doesn&apos;t update with new content:</b>
          <br />
          - Log out and log back in periodically.
          <br />
          - Keeping the site open for too long may prevent it from refreshing automatically.
          <br />
          These steps should help resolve common access and update issues.
          <br />
        </p>
        {/* {!IS_CISCO && (
          <p className="pt-96 max-sm:pt-14 max-sm:text-center">
            By continuing, you agree to {IS_CISCO ? 'Purposefy' : 'Hypha'}’s{' '}
            <Link
              aria-label={`link to privacy policy`}
              to="/privacy-policy"
              target="_blank"
              className="underline"
            >
              Privacy Policy
            </Link>
            .
          </p>
        )} */}
      </main>
    </div>
  )
}
