import { Button } from '@/ui'
import { closeSession } from '@/store/session/slice'
import { useDispatch } from 'react-redux'

export const RendererError: React.FC = () => {
  const dispatch = useDispatch()

  return (
    <div className="grid justify-items-center content-center gap-3 h-screen">
      <p>A browser error has occurred.</p>
      <Button onClick={() => dispatch(closeSession())}>Reload site</Button>
    </div>
  )
}
